import React from 'react';
import Layout from "../components/layouts";
import image from "../images/art-of-recycling-exhibition-2024.jpg";
import image2 from "../images/art-of-recycling-exhibition-2024-02.jpg";
const RSVP = () => {
 
  return (
    <Layout navBg>
        <div className="carpoolchella-page">
        <div className="carpoolchella-inner">
          <div className="carpoolchella-description">
              <h1>2024 TRASHed: Art of Recycling Coachella Collection</h1>
              <p>April 6th, 2024 7:00pm - 11:00pm</p>
              <img src={image} style={{ width: '100%', maxWidth: 700}}/>
              <p>
              ONE NIGHT ONLY - Global Inheritance and Coachella present <strong>TRASHed: Art of Recycling opening night exhibit in Downtown Los Angeles on Saturday Night, April 6th, from 7 pm to 11 pm at House of Cocotte.</strong> Experience the most unique collection of redesigned recycling bins in the world before they roll to the Empire Polo Grounds for Coachella 2024. Capacity is limited, so RSVP today to reserve your spot!</p>
<p><i><strong>2024 TRASHed Artists Include:</strong></i></p>
<p>
Alberto • Almonte Studio • Andrade Designs • ART one • Brandon Sopinsky • Bryan Phillips • Celina Serenity • Cesar Torres • Change The Game • Daisy Carlene • Dart • Emme J • fcstilukart • Gem Jaxx • Gifted soul • Gnarmaster • Hero • Ida Shokoh • It's a Disco Day • Jacob Livengood • Jarrett Quon  • Jennifer Saucedo • Joey Koslik • Jourdan Louise • Kawena • LA Hope Dealer • Las Chicas Peligrosas • Mariusz  Mierzwa • Mel Depaz • Mia Matz • Mr. Barwinski • P Behind The Art • Rosemont Lane • Roy Ceballos • SEY • Shast • sushibaby • Sydney Oliff Art & Design • Tayah Cluett • VMR3 • yaicecream • Zee • ZLA

</p>
<p>
The TRASHed (Stands for trash education): Art of Recycling program has been an integral part of the Coachella Valley Music & Arts Festival since 2004, bringing art and sustainability to millions of festival attendees.
</p>
<p><strong>Join us on the night of April 6th for drinks and music and to celebrate the 20th edition of TRASHed: Art of Recycling program at Coachella.</strong> Meet this year’s bin artists and support Global Inheritance’s Art of Recycling school gifting program by participating in our TRASHed: Art of Recycling mini-recycling bin auction. In doing so, you can help us launch sustainability programming at local school campuses and classrooms. These are one-of-a-kind art pieces and exclusive TRASHed x Coachella 2024 memorabilia that you won’t be able to find anywhere else!</p>
<p>For more information or questions, email <a href="mailto:ArtOfRecycling@globalinheritance.org">ArtOfRecycling@globalinheritance.org</a></p>
<img src={image2} style={{ width: '100%', maxWidth: 700}}/>

          </div>
          <div className="carpoolchella-body">
            <givebutter-widget id="LZM4lj"></givebutter-widget>
        </div>
      </div>
        </div>
    </Layout>
);
}

export default RSVP;


